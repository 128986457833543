body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media print {

  /* Ensure the whole page scales down if content overflows */
  body {
    -webkit-print-color-adjust: exact;
    /* Preserve colors in print */
    font-size: 10px;
    /* Reduce font size for printing */
  }

  /* Scale down images to fit within the print */
  img {
    max-width: 100%;
    /* Make sure images do not overflow */
    height: auto;
  }

  /* Ensure tables are printed without getting cut off */
  table {
    width: 100%;
    border-collapse: collapse;
    /* Make sure borders are solid */
  }

  /* Avoid breaking rows mid-table */
  tr,
  td,
  th {
    page-break-inside: avoid;
    /* Prevent table rows from being split */
  }

  /* Handle page breaks after larger elements */
  .table {
    page-break-inside: avoid;
    /* Avoid breaking table mid-page */
  }

  /* Hide elements that are not needed for printing */
  .no-print,
  .add-btn,
  .delete-btn,
  .edit-btn {
    display: none !important;
    /* Hide buttons or any element with the class 'no-print' */
  }

  /* Adjust margins for the printed page */
  @page {
    margin: 1cm;
    /* Smaller margins to fit more content */
  }

  /* Ensure modal or unnecessary content isn't printed */
  .modal {
    display: none !important;
  }
}